import React from "react";
import { Section, Row, Column } from "@react-email/components";
import { IShopifyData } from "../../../../../ShopifyData";
import { BodyXs } from "../../../../components/Text";
import { formatTemplateVariable } from "../../../../utils";
import SMARTRR_COLORS from "../../../../../../branding/colors";
import { ICustomerEmailTheming } from "../../../../types";

export interface EmailFooterWithAddressProps {
  theming: ICustomerEmailTheming;
}

export const ShopFooterWithAddress = ({ shopifyData }: { shopifyData: IShopifyData }) => {
  return (
    <Section>
      {"<% if(theming.useHeaderAndFooter) { %>"}
      <Section
        style={{ borderTop: `1px solid ${SMARTRR_COLORS.neutralGrey}` }}
        data-testid="email-footer-wrapper"
      >
        <Row style={{ margin: "30px 0 0 " }}>
          <Column>
            <BodyXs style={{ color: formatTemplateVariable("theming.colors.secondaryTextColor") }}>
              {shopifyData.name}
            </BodyXs>
          </Column>
        </Row>
        <Row>
          <Column>
            <BodyXs style={{ color: formatTemplateVariable("theming.colors.secondaryTextColor") }}>
              {shopifyData.address1 ? shopifyData.address1.trim() : ""}
              {shopifyData.address2 ? `, ${shopifyData.address2.trim()}` : ""}, {shopifyData.city},{" "}
              {shopifyData.province} {shopifyData.zip}
            </BodyXs>
          </Column>
        </Row>
        <Row>
          <Column>
            <BodyXs style={{ color: formatTemplateVariable("theming.colors.secondaryTextColor") }}>
              {shopifyData.customer_email}
            </BodyXs>
          </Column>
        </Row>
      </Section>
      {"<% } %>"}
    </Section>
  );
};
